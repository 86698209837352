<template>
  <div class="title">
    <div class="logo">
      <img class="image" src="../assets/image/logo.jpg">
      <div class="title-name">
        <div>滨水植物数据库</div>
        <div style="font-size: 14px;opacity: 0.7;">Waterfront Plant Database</div>
      </div>
    </div>
    <div class="middle">
      <div style="text-align: left;">基于工程设计需求的</div>
      <div style="text-align: right;">福州市滨水植物数据库</div>
    </div>
    <div class="login" @click="toLogin" v-if="this.login">
      登录
    </div>
    <div class="login" @click="toQuit" v-if="!this.login">
      退出
    </div>
  </div>

  <el-menu
    :default-active="this.$route.path"
    class="el-menu-demo"
    mode="horizontal"
    :router="true"
    background-color="#545c64"
    text-color="#fff"
    active-text-color="#ffd04b">
    <el-menu-item index="/" route="/">首页</el-menu-item>
    <el-menu-item index="/search" route="/search">智能检索</el-menu-item>
    <el-menu-item index="/picture" route="/picture">植物名录</el-menu-item>
    <el-menu-item index="/application" route="/application">应用案例</el-menu-item>
    <el-menu-item index="/aboutus" route="/aboutus">关于我们</el-menu-item>
  </el-menu>
</template>


<script>
export default {
    data() {
      return {
        login: true,
      };
    },
    methods: {
      toLogin() {
        this.$router.push({ path: '/login'})
      },
      toQuit() {
        localStorage.removeItem('token')
        this.$router.push({ path: '/login'})
      }
    },
    created() {
      if(localStorage.getItem('token') != null) {
        this.login = false
      } else {
        this.login = true
      }
    }
  }
</script>

<style scoped>
.title {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  height: 125px;
}
.logo {
  display: flex;
  align-items: center;
}
.image {
  width: 75px;
  height: 75px;
}
.title-name {
  font-size: 24px;
  margin-left: 20px;
}
.middle {
  width: 35%;
  margin-left: -100px;
  font-size: 43px;
  font-family: 'YouSheBiaoTiHei';
}
.login {
  font-size: 20px;
  cursor: pointer;
}
.el-menu-demo {
  height: 50px;
}
.el-menu--horizontal {
    justify-content: center;
}
.el-menu--horizontal>.el-menu-item {
    margin: 0 50px !important;
    font-size: 17px;
    border-bottom: 0 !important;
}
.el-menu--horizontal.el-menu {
    border-bottom: 0 !important;
}
</style>