import axios from "axios";

const instance = axios.create({
    // baseURL: "http://175.178.34.86:8088/",
    // baseURL: "http://114.55.224.252:8088/",
    baseURL: "https://plant.zhangyang2333.cn/api/",
    timeout: 1000,
    headers: {
        "Content-Type": "application/json",
    },
});
// 请求拦截器
instance.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem("token");
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    (error) => {
        // 请求错误处理
        console.error(error);
        return Promise.reject(error);
    }
);

export default instance;
