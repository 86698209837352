<template>
    <div class="footer">
        <div class="logo">
            <img class="image" src="../assets/image/logo2.png">
            <div class="title-name">
                <div>滨水植物数据库</div>
                <div style="font-size: 12px;opacity: 0.7;">Waterfront Plant Database</div>
            </div>
        </div>
        <div>
            <div class="programs">
                <div class="title">
                    <div>基金项目:</div>
                </div>
                <div>
                    <div class="program">福建省水利厅科技项目（MSK201917）</div>
                    <div class="program">福建省教育厅科技项目（JA13222）</div>
                    <div class="program">福建省大学生创新创业训练计划项目（S202310388080）</div>
                </div>
            </div>
            <div class="programs">
                <div class="address">
                    <div>地址:</div>
                </div>
                <div>
                    <div class="program">福建省福州市</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {

}
</script>

<style scoped>
.footer{
    width: 100%;
    height: 125px;
    background-color: #727272;
    display: flex;
    justify-content: space-around;
    text-align: center;
    align-items: center;
}
.logo {
  display: flex;
  align-items: center;
  color: white;
}
.image {
  width: 50px;
  height: 50px;
}
.title-name {
  font-size: 20px;
  margin-left: 15px;
}
.message {
    color: white;
}
.programs {
    color: white;
    display: flex;
}
.program {
    text-align: start;
}
.title {
    width: 75px;
    margin-right: 10px;
}
.address {
    width: 75px;
    text-align: end;
    margin-right: 10px;

}
</style>