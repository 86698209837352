<template>
    <navbar/>
    <div class="main">
        <div class="top">
            <div class="search">
                <el-autocomplete
                    class="input"
                    v-model="searchValue"
                    :placeholder="placeholder"
                    :fetch-suggestions="querySearch"
                    @select="handleSelect"
                    @keyup.enter="handleChange"
                    ref="handleCancel"
                    clearable
                    >
                    <template #prepend>
                        <el-select v-model="select" @change="changeSelect">
                            <el-option label="植物检索" value="1"></el-option>
                            <el-option label="科名检索" value="2"></el-option>
                            <el-option label="驳岸类型检索" value="3"></el-option>
                        </el-select>
                    </template>
                </el-autocomplete>
                <el-button type="primary" class="searh-button" @click="handleChange"><el-icon :size="20"><Search /></el-icon>搜索</el-button>
            </div>
        </div>
        <div class="title" v-if="!this.show">
            推荐：
        </div>
        <div class="content" v-if="!this.show">
            <div class="item" v-for="item in recomlist1" :key="item" @click="toPlantDetail(item)">
                <el-card :body-style="{ padding: '0px', height: '280px' }">
                    <img :src="item.images[0].imageUrl"/>
                    <div style="padding: 14px;display: flex;flex-direction: column;text-align: center;">
                        <span>{{ item.name }}</span>
                        <span>{{ item.Latin }}</span>
                    </div>
                </el-card>
            </div>
        </div>
        <div class="content" v-if="!this.show">
            <div class="item" v-for="item in recomlist2" :key="item" @click="toAppDetail(item)">
                <el-card :body-style="{ padding: '0px', height: '280px' }">
                    <img :src="item.images[0].imageUrl"/>
                    <div style="padding: 14px;display: flex;flex-direction: column;text-align: center;">
                        <span>{{ item.name }}</span>
                        <div style="display: flex;justify-content: space-evenly;">
                            <span v-for="item1 in item.plants" :key="item1">{{ item1.name }}</span>
                        </div>
                    </div>
                </el-card>
            </div>
        </div>
        <div class="title" v-if="this.show">
            搜索结果：
        </div>
        <div class="content" v-if="this.show && !this.isApplication">
            <div class="item" v-for="item in itemlist" :key="item" @click="toPlantDetail(item)">
                <el-card :body-style="{ padding: '0px', height: '280px' }">
                    <img :src="item.images[0].imageUrl"/>
                    <div style="padding: 14px;display: flex;flex-direction: column;text-align: center;">
                        <span>{{ item.name }}</span>
                        <span>{{ item.Latin }}</span>
                    </div>
                </el-card>
            </div>
        </div>
        <div class="content" v-if="this.show && this.isApplication">
            <div class="item1" v-for="item in itemlist" :key="item" @click="toAppDetail(item)">
                <el-card :body-style="{ padding: '0px', height: '325px' }">
                    <img :src="item.images[0].imageUrl"/>
                    <div style="padding: 14px;display: flex;flex-direction: column;text-align: center;color: white;background-color: #42666e;">
                        <span>{{ item.name }}</span>
                        <div style="display: flex;justify-content: space-evenly;">
                            <span v-for="item1 in item.plants" :key="item1">{{ item1.name }}</span>
                        </div>
                    </div>
                </el-card>
            </div>
        </div>
        <div class="pagination" v-if="this.show">
            <el-pagination
                background
                layout="prev, pager, next"
                :current-page="pageNum"
                :page-size="pageSize"
                :total="total"
                @size-change="handleSizeChange"
                @current-change="handlePageChange"
                />
        </div>
    </div>
    <Footer/>
</template>

<script>
import api from '@/router/axios.js'
import Navbar from '@/components/Navbar.vue'
import Footer from '@/components/Footer.vue'
import { Search, } from '@element-plus/icons-vue'
export default {
    components: {
        Navbar,
        Footer,
        Search,
    },
    data() {
        return {
            searchValue: '',
            pageNum: 1,
            pageSize: 9,
            total: 0,
            recommendations: [{value:''}],
            recomlist1: [],
            recomlist2: [],
            itemlist: [],
            show: false,
            select: '1',
            placeholder:'请输入植物名称',
            familyId: '',
            isApplication: false,
        }
    },
    methods: {
        handlePageChange(currentPage) {
            this.pageNum = currentPage
            if(this.select === '1') {
                this.getList1()
            } else if(this.select === '2') {
                this.getList2()
            } else if(this.select === '3') {
                this.getList3()
            }  
        },
        handleSizeChange(pageSize) {
            this.pageSize = pageSize
        },
        querySearch(queryString, cb) {
            var restaurants = this.recommendations;
            var results = queryString ? restaurants.filter(this.createFilter(queryString)) : restaurants;
            // 调用 callback 返回建议列表的数据
            cb(results);
        },
        createFilter(queryString) {
            return (restaurant) => {
                return (restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
            };
        },
        handleSelect() {
            this.$refs.handleCancel.blur()
            if(this.select === '1') {
                this.getList1()
            } else if(this.select === '2') {
                this.getList2()
            } else if(this.select === '3') {
                this.getList3()
            }  
        },
        handleChange() {
            if(this.searchValue === '') {
                this.$message({
                    message: '请输入搜索内容',
                    type: 'warning'
                });
            } else if(this.select === '1') {
                this.getList1()
            } else if(this.select === '2') {
                this.getList2()
            } else if(this.select === '3') {
                this.getList3()
            }
        },
        toPlantDetail(data) {
            this.$router.push({ path: '/plant-detail', query: { data: JSON.stringify(data) }});
        },
        toAppDetail(data) {
            this.$router.push({ path: '/app-detail', query: { data: JSON.stringify(data)}});
        },
        changeSelect() {
            if(this.select === '1') {
                this.placeholder = '请输入植物名称'
                this.searchValue = ''
                this.getRecomList1()
            } else if(this.select === '2') {
                this.placeholder = '请输入科名名称'
                this.searchValue = ''
                this.getRecomList2()
            } else if(this.select === '3') {
                this.placeholder = '请输入驳岸类型名称'
                this.searchValue = ''
                this.getRecomList3()
            }
        },
        getList1() {
            api.post('/plant/getPlantByKey?key=' + this.searchValue + '&page=' + this.pageNum + '&pageSize=' + this.pageSize)
                .then((res)=> {
                    if(res.data.data.data.length === 0) {
                        this.$message.error('暂无该植物信息')
                    } else {
                        this.show = true
                        this.isApplication = false
                        this.total = res.data.data.numbers
                        this.itemlist = res.data.data.data
                        this.itemlist.forEach((item) => {
                            item.images.forEach((item) => {
                                item.imageUrl = 'https://plant-database.oss-cn-beijing.aliyuncs.com/' + item.imageUrl
                            })
                        })
                    }
                })
                .catch((error)=> {
                    console.log(error)
                })
        },
        getList2() {
            const foundItem = this.recommendations.find((item) => this.searchValue === item.value)
            this.familyId = foundItem ? foundItem.id : '0'
            api.post('/plant/getPlantByFamily?Family=' + this.familyId + '&page=' + this.pageNum + '&pageSize=' + this.pageSize)
                .then((res)=> {
                    if(res.data.data.data.length === 0) {
                        this.$message.error('暂无该科名类型')
                    } else {
                        this.show = true
                        this.isApplication = false
                        this.total = res.data.data.numbers
                        this.itemlist = res.data.data.data
                        this.itemlist.forEach((item) => {
                            item.images.forEach((item) => {
                                item.imageUrl = 'https://plant-database.oss-cn-beijing.aliyuncs.com/' + item.imageUrl
                            })
                        })
                    }
                })
                .catch((error)=> {
                    console.log(error)
                })
        },
        getList3() {
            api.post('/revetment/getRevetmentByName?name=' + this.searchValue + '&page=' + this.pageNum + '&pageSize=' + this.pageSize)
                .then((res)=> {
                    if(res.data.data.data.length === 0) {
                        this.$message.error('暂无该驳岸类型')
                    } else {
                        this.show = true
                        this.isApplication = true
                        this.total = res.data.data.numbers
                        this.itemlist = res.data.data.data
                        this.itemlist.forEach((item) => {
                            item.images.forEach((item) => {
                                item.imageUrl = 'https://plant-database.oss-cn-beijing.aliyuncs.com/' + item.imageUrl
                            })
                        })
                    }
                })
                .catch((error)=> {
                    console.log(error)
                })
        },
        getRecomList() {
            api.post('/plant/getPlantByKey?page=1&pageSize=6')
                .then((res)=> {
                    this.recomlist1 = res.data.data.data
                    this.recomlist1.forEach((item) => {
                        item.images.forEach((item) => {
                            item.imageUrl = 'https://plant-database.oss-cn-beijing.aliyuncs.com/' + item.imageUrl
                        })
                    })
                })
                .catch((error)=> {
                    console.log(error)
                })
        },
        getRecomList1() {
            api.post('/plant/getPlantByKey?page=1&pageSize=1000')
                .then((res)=> {
                    this.recommendations = []
                    res.data.data.data.forEach((item) => {
                        this.recommendations.push({ value: item.name })
                    })
                })
                .catch((error)=> {
                    console.log(error)
                })
        }, 
        getRecomList2() {
            api.get('/plantFamily/getAllPlantFamily')
                .then((res)=> {
                    this.recommendations = []
                    res.data.data.forEach((item) => {
                        this.recommendations.push({ value: item.name, id: item.id })
                    })
                })
                .catch((error)=> {
                    console.log(error)
                })
        }, 
        getRecomList3() {
            api.post('/revetment/getRevetmentType')
                .then((res)=> {
                    this.recommendations = []
                    res.data.data.forEach((item) => {
                        this.recommendations.push({ value: item.name, id: item.id })
                    })
                })
                .catch((error)=> {
                    console.log(error)
                })
        }, 
    },
    created() {
        this.show = false;
        this.getRecomList();
        this.getRecomList1();
    }
}
</script>

<style scoped>
.main {
    width: 70%;
    margin: 0 15%;
    margin-bottom: 100px;
    display: flex;
    flex-direction: column;
}
.top {
    display: flex;
    flex-direction: column;
}
.search {
    display: flex;
    margin: 50px;
    justify-content: center;
    align-items: center;
}
.searh-button {
    margin-left: 25px;
    height: 50px;
    font-size: 17px;
}
.content {
    display: flex;
    flex-wrap: wrap;
    margin: 0 24px;
}
.item {
    width: 30%;
    display: flex;
    flex-direction: column;
    margin: 1.5%;
}
.item img {
    width: 100%;
    height: 200px;
}
.item1 {
    width: 47%;
    display: flex;
    flex-direction: column;
    margin: 1.5%;
}
.item1 img {
    width: 100%;
    height: 250px;
}
.title {
    font-size: 20px;
    display: flex;
    margin-bottom: 25px;
}
.pagination {
    display: flex;
    justify-content: center;
    margin-top: 50px;
}
::v-deep .el-autocomplete {
    width: 75%;
    height: 50px;
}
::v-deep .el-input-group {
    height: 100%;
}
::v-deep .el-input {
    height: 50px;
}
::v-deep .el-input-group__prepend {
    width: 12%;
}
</style>