<template>
    <navbar/>
    <!-- 轮播图 -->
    <div class="carousel">
        <el-carousel :interval="5000" arrow="always">
            <el-carousel-item v-for="item in imglist" :key="item">
                <img :src="item.url">
            </el-carousel-item>
        </el-carousel>
    </div>
    
    <!-- 内容 -->
    <div class="content">
        <!-- 统计信息 -->
        <div class="static">
            <div class="static-item">
                <el-icon :size="50"><Collection /></el-icon>
                <div class="static-text">
                    <div>数据采集</div>
                    <div>{{Statistics.plantCount}}条</div>
                </div>
            </div>
            <div class="static-item">
                <el-icon :size="50"><Picture /></el-icon>
                <div class="static-text">
                    <div>植物图片</div>
                    <div>{{Statistics.plantImageCount}}张</div>
                </div>
            </div>
            <div class="static-item">
                <el-icon :size="50"><Tickets /></el-icon>
                <div class="static-text">
                    <div>应用案例</div>
                    <div>{{Statistics.revetment}}个</div>
                </div>
            </div>
        </div>

        <div class="main">
            <div class="main-new">
                <div class="title">
                    <div>综合新闻</div>
                    <div class="more" @click="toNew">更多<el-icon :size="20"><ArrowRight /></el-icon></div>
                </div>
                <div class="new-context">
                    <ul class="new-item" v-for="item in newslist" :key="item" @click="toNewDetail(item)">
                        <li class="new-title">{{item.title}}</li>
                        <span class="new-time">{{item.time}}</span>
                    </ul>
                </div>
            </div>
            <div class="main-introduce">
                <div class="title">
                    <div>数据库介绍</div>
                </div>
                <div class="introduction">
                    <p>该数据库收集整理了各种类型的滨水植物信息，包括植物的名称、特征、生长习性、适宜生长环境等内容。通过该数据库，工程设计师可以根据具体的驳岸类型查询到适合的滨水植物种类，并了解它们的生长特点和适用条件。同时，该数据库还提供了对应植物的图片和实际案例展示，帮助设计师更直观地了解不同植物的外观特征和实际应用效果。</p>
                </div>
            </div>
        </div>

        <div class="middle">
            <div class="main-popular">
                <div class="title">
                    <div>信息服务</div>
                    <!-- <div class="more">更多<el-icon :size="20"><ArrowRight /></el-icon></div> -->
                </div>
                <div class="webs">
                    <div class="web" v-for="item in weblist" :key="item" @click="toNewDetail(item)">
                        <img :src="item.url">
                    </div>
                </div>
            </div>
            <div class="plants">
                <div class="title">
                    <div>植物名录</div>
                    <div class="more" @click="toPicture">更多<el-icon :size="20"><ArrowRight /></el-icon></div>
                </div>
                <div class="plants-img">
                    <el-carousel :interval="5000" arrow="always">
                        <el-carousel-item v-for="item in plantlist" :key="item" @click="toPlantDetail(item)">
                            <img :src="item.images[0].imageUrl">
                        </el-carousel-item>
                    </el-carousel>
                </div>
            </div>
        </div>

        <div class="application">
            <div class="title">
                <div>应用案例</div>
                <div class="more" @click="toApplication">更多<el-icon :size="20"><ArrowRight /></el-icon></div>
            </div>
            <el-carousel :interval="4000" type="card" style="margin-top: 25px;">
                <el-carousel-item v-for="item in applist" :key="item" @click="toAppDetail(item)">
                    <img :src="item.images[0].imageUrl">
                </el-carousel-item>
            </el-carousel>
        </div>
    </div>
    <Footer/>
</template>

<script>
import api from '@/router/axios.js'
import Navbar from '@/components/Navbar.vue'
import Footer from '@/components/Footer.vue'
import { Collection, Tickets, Picture, ArrowRight } from '@element-plus/icons-vue'
export default {
    components: { 
        Navbar,
        Footer,
        Collection,
        Tickets,
        Picture,
        ArrowRight,
    },
    data() {
        return {
            Statistics: {
                plantCount: 0,
                plantImageCount: 0,
                revetment: 0,
            },
            imglist: [
                {
                    url: require('../assets/image/carousel1.jpg')
                },
                {
                    url: require('../assets/image/carousel2.jpg')
                },
                {
                    url: require('../assets/image/carousel3.jpg')
                },
                {
                    url: require('../assets/image/carousel4.jpg')
                }
            ],
            newslist: [
                {
                    title: '中国科学院副院长张亚平调研植物所',
                    time: '2024-02-04',
                    src: 'http://ib.cas.cn/2019gb/News2019/202402/t20240204_6985604.html',
                },
                {
                    title: '国家植物标本资源库在东亚常绿阔叶林起源和演化历史研究取得进展',
                    time: '2024-02-01',
                    src: 'https://www.cvh.ac.cn/articles/details.php?id=c5d50a3c',
                },
                {
                    title: '国家重点研发计划项目“代表性珍稀濒危植物的保育和种群恢复技术”2023年度进展报告会在植物所召开',
                    time: '2024-02-01',
                    src: 'http://ib.cas.cn/2019gb/News2019/202402/t20240201_6984720.html',
                },
                {
                    title: '植被与环境变化国家重点实验室召开2023年度学术年会',
                    time: '2024-01-31',
                    src: 'http://ib.cas.cn/2019gb/News2019/202401/t20240131_6982186.html',
                },
                {
                    title: '国家植物标本资源库在中国外来入侵植物空间分布格局及优先防控方面取得新进展',
                    time: '2024-01-17',
                    src: 'https://www.cvh.ac.cn/articles/details.php?id=43ba7c48',
                },
                {
                    title: '大数据+植物 会产生哪些神奇效应？',
                    time: '2024-01-08',
                    src: 'https://www.plantplus.cn/cn/news/2192',
                },
                {
                    title: '长江上游发现野生疏花水柏枝新分布群落',
                    time: '2023-10-31',
                    src: 'http://www.nsii.org.cn/2017/wikilet.php?w=LocalNews_2023031'
                },
            ],
            weblist: [
                {
                    url: require('../assets/image/website1.png'),
                    src: 'http://ib.cas.cn/'
                },
                {
                    url: 'http://ib.cas.cn/2019gb/kyxxfw/202103/W020220610551612515350.jpg',
                    src: 'https://www.plantplus.cn/cn'
                },
                {
                    url: 'http://ib.cas.cn/2019gb/kyxxfw/202004/W020220627546675930303.jpg',
                    src: 'http://www.cvh.ac.cn/'
                },
                {
                    url: 'http://ib.cas.cn/2019gb/kyxxfw/202004/W020220610551411583597.jpg',
                    src: 'http://www.nsii.org.cn/2017/'
                },
                {
                    url: require('../assets/image/website2.png'),
                    src: 'https://www.iplant.cn/'
                },
                {
                    url: 'https://ppbc.iplant.cn/images/ppbcbiglogo_h.png',
                    src: 'https://ppbc.iplant.cn/'
                },
            ],
            plantlist: [],
            applist: [],
        }
    },
    methods: {
        getStatistics() {
            api.get('/plant/getStatistics')
                .then((res)=> {
                    this.Statistics.plantCount = res.data.data.plantCount
                    this.Statistics.plantImageCount = res.data.data.plantImageCount
                    this.Statistics.revetment = res.data.data.revetment
                })
                .catch((error)=> {
                    console.log(error)
                })
        },
        getPlantList() {
            api.post('/plant/getPlantByKey?key=美人蕉&page=1&pageSize=1')
                .then((res)=> {
                    this.plantlist.push(res.data.data.data[0])
                    this.plantlist.forEach((item) => {
                        if(item.id == res.data.data.data[0].id) {
                            item.images.forEach((item) => {
                                item.imageUrl = 'https://plant-database.oss-cn-beijing.aliyuncs.com/' + item.imageUrl
                            })
                        }
                    })
                })
                .catch((error)=> {
                    console.log(error)
                })
            api.post('/plant/getPlantByKey?key=梭鱼草&page=1&pageSize=1')
                .then((res)=> {
                    this.plantlist.push(res.data.data.data[0])
                    this.plantlist.forEach((item) => {
                        if(item.id == res.data.data.data[0].id) {
                            item.images.forEach((item) => {
                                item.imageUrl = 'https://plant-database.oss-cn-beijing.aliyuncs.com/' + item.imageUrl
                            })
                        }
                    })
                })
                .catch((error)=> {
                    console.log(error)
                })
            api.post('/plant/getPlantByKey?key=小叶榕&page=1&pageSize=1')
                .then((res)=> {
                    this.plantlist.push(res.data.data.data[0])
                    this.plantlist.forEach((item) => {
                        if(item.id == res.data.data.data[0].id) {
                            item.images.forEach((item) => {
                                item.imageUrl = 'https://plant-database.oss-cn-beijing.aliyuncs.com/' + item.imageUrl
                            })
                        }
                    })
                })
                .catch((error)=> {
                    console.log(error)
                });
        },
        getAppList() {
            api.post('/revetment/getRevetmentByName?name=自然式驳岸&page=1&pageSize=1')
                .then((res)=> {
                    this.applist.push(res.data.data.data[0])
                    this.applist.forEach((item) => {
                        if(item.id == res.data.data.data[0].id) {
                            item.images.forEach((item) => {
                                item.imageUrl = 'https://plant-database.oss-cn-beijing.aliyuncs.com/' + item.imageUrl
                            })
                        }
                    })
                })
                .catch((error)=> {
                    console.log(error)
                });
            api.post('/revetment/getRevetmentByName?name=砌石驳岸&page=1&pageSize=1')
                .then((res)=> {
                    this.applist.push(res.data.data.data[0])
                    this.applist.forEach((item) => {
                        if(item.id == res.data.data.data[0].id) {
                            item.images.forEach((item) => {
                                item.imageUrl = 'https://plant-database.oss-cn-beijing.aliyuncs.com/' + item.imageUrl
                            })
                        }
                    })
                })
                .catch((error)=> {
                    console.log(error)
                });
            api.post('/revetment/getRevetmentByName?name=阶梯入水驳岸&page=1&pageSize=1')
                .then((res)=> {
                    this.applist.push(res.data.data.data[0])
                    this.applist.forEach((item) => {
                        if(item.id == res.data.data.data[0].id) {
                            item.images.forEach((item) => {
                                item.imageUrl = 'https://plant-database.oss-cn-beijing.aliyuncs.com/' + item.imageUrl
                            })
                        }
                    })
                })
                .catch((error)=> {
                    console.log(error)
                });
            api.post('/revetment/getRevetmentByName?name=垂直驳岸&page=1&pageSize=1')
                .then((res)=> {
                    this.applist.push(res.data.data.data[0])
                    this.applist.forEach((item) => {
                        if(item.id == res.data.data.data[0].id) {
                            item.images.forEach((item) => {
                                item.imageUrl = 'https://plant-database.oss-cn-beijing.aliyuncs.com/' + item.imageUrl
                            })
                        }
                    })
                })
                .catch((error)=> {
                    console.log(error)
                });
            api.post('/revetment/getRevetmentByName?name=复合式驳岸&page=1&pageSize=1')
                .then((res)=> {
                    this.applist.push(res.data.data.data[0])
                    this.applist.forEach((item) => {
                        if(item.id == res.data.data.data[0].id) {
                            item.images.forEach((item) => {
                                item.imageUrl = 'https://plant-database.oss-cn-beijing.aliyuncs.com/' + item.imageUrl
                            })
                        }
                    })
                })
                .catch((error)=> {
                    console.log(error)
                });
        },
        toNew() {
            window.open('http://ib.cas.cn/', '_blank');
        },
        toNewDetail(item) {
            window.open(item.src, '_blank');
        },
        toPicture() {
            this.$router.push('/picture')
        },
        toApplication() {
            this.$router.push('/application')
        },
        toPlantDetail(data) {
            this.$router.push({ path: '/plant-detail', query: { data: JSON.stringify(data) }});
        },
        toAppDetail(data) {
            this.$router.push({ path: '/app-detail', query: { data: JSON.stringify(data)}});
        },
    },
    created() {
        this.getStatistics();
        this.getPlantList();
        this.getAppList();
    },
}
</script>

<style scoped>
.carousel img {
    height: 300px;
    width: 100%;
    cursor: pointer;
}
.content {
    width: 70%;
    margin: 0 15%;
    margin-bottom: 100px;
    display: flex;
    flex-direction: column;
}
.static {
    display: flex;
    align-items: center;
    justify-content: space-around;
    height: 100px;
    margin: 25px 0;
}
.static-item {
    display: flex;
    align-items: center;
}
.static-text {
    font-size: 22px;
    color: green;
    margin-left: 25px;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.main {
    display: flex;
}
.main-new {
    width: 55%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.title {
    font-size: 24px;
    width: 100%;
    border-bottom: 2px solid black;
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
}
.more {
    font-size: 20px;
    display: flex;
    align-items: center;
    cursor: pointer;
}
.new-context {
    width: 100%;
}
.new-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 0;
    cursor: pointer;
}
.new-title {
    width: 85%;
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.main-introduce {
    margin-left: 110px;
    width: 35%;
}
.introduction {
    width: 100%;
}
.introduction p {
    text-indent: 2em;
    font-size: 16px;
    line-height: 36px;
}
.webs {
    display: flex;
    flex-wrap: wrap;
}
.web {
    width: 46%;
    height: 90px;
    margin: 1% 2%;
    cursor: pointer;
}
.web img {
    width: 100%;
    height: 100%;
}
.middle {
    margin-top: 50px;
    display: flex;
}
.main-popular {
    width: 55%;
}
.plants {
    margin-left: 110px;
    width: 35%;
}
.plants 
.plants-img img {
    height: 100%;
    width: 100%;
}
.application {
    margin-top: 50px;
}
.application img {
    height: 298px;
    width: 99%;
    border: 1px solid;
}
</style>