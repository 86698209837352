<template>
    <navbar/>
    <div class="main">
        <el-page-header @back="onBack">
            <template #content>
                <span class="title">{{this.FormData.name}} {{this.FormData.Latin}}</span>
                <div>{{this.FormData.anoname}}</div>
            </template>
        </el-page-header>
        <div class="content">
            <div class="con-left">
                <div class="item">
                    <div class="con-title">
                        介绍
                    </div>
                    <div class="text">
                        {{this.FormData.introduce}}
                    </div>
                </div>
                <div class="item">
                    <div class="con-title">
                        分布位置情况
                    </div>
                    <div class="text">
                        {{this.FormData.distribution}}
                    </div>
                </div>
                <div class="item">
                    <div class="con-title">
                        利用价值
                    </div>
                    <div class="text">
                        {{this.FormData.value}}
                    </div>
                </div>
                <div class="item">
                    <div class="con-title">
                        图片
                    </div>
                    <div class="image">
                        <el-image style="width: 80%" :src="this.srcList[0]" :preview-src-list="this.srcList"/>
                    </div>
                </div>
            </div>
            <div class="con-right">
                <div class="application">
                    应用案例
                </div>
                <div v-for="item in this.appList" :key="item" @click="toAppDetail(item)">
                    <div style="text-align: center;">{{ item.name }}</div>
                    <img :src="item.images[0].imageUrl">
                </div>
                <div v-if="this.show" style="text-align: center;margin: 40px 0;">暂无数据</div>
                <div class="more" @click="toApplication">更多<el-icon :size="22"><ArrowRight /></el-icon></div>
            </div>
        </div>
    </div>
    <Footer/>
</template>

<script>
import api from '@/router/axios.js'
import Navbar from '@/components/Navbar.vue'
import Footer from '@/components/Footer.vue'
import { ArrowRight } from '@element-plus/icons-vue'
export default {
    components: {
        Navbar,
        Footer,
        ArrowRight,
    },
    data() {
        return {
            FormData: {},
            srcList: [],
            itemlist: [],
            appList: [],
            show: false,
        }
    },
    created() {
        this.FormData = JSON.parse(this.$route.query.data);
        this.FormData.images.forEach((item) => {
            this.srcList.push(item.imageUrl)
        })
        this.getList()
    },
    methods: {
        onBack() {
            this.$router.back();
        },
        toApplication() {
            this.$router.push('/application')
        },
        toAppDetail(data) {
            this.$router.push({ path: '/app-detail', query: { data: JSON.stringify(data)}});
        },
        getList() {
            api.post('/revetment/getRevetmentByName?page=1&pageSize=100')
                .then((res)=> {
                    this.itemlist = res.data.data.data
                    this.itemlist.forEach((item) => {
                        item.images.forEach((item) => {
                            item.imageUrl = 'https://plant-database.oss-cn-beijing.aliyuncs.com/' + item.imageUrl
                        })
                        item.plants.forEach((item1) => {
                            if(this.appList.length < 3) {
                                if(item1.id === this.FormData.id) {
                                    this.appList.push(item)
                                }
                            }
                        })
                    })
                    if(this.appList.length == 0) {
                        this.show = true
                    }
                })
                .catch((error)=> {
                    console.log(error)
                })
        }
    }
}
</script>

<style scoped>
.main {
    width: 70%;
    margin: 50px 15%;
    display: flex;
    flex-direction: column;
}
.title {
    margin-top: 50px;
    font-size: 26px;
}
.content {
    margin-left: 150px;
    display: flex;
    align-items: flex-start;
}
.item {
    margin: 20px 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 90%;
    border-top: 1px solid rgb(164, 164, 164);
}
.con-title {
    font-size: 22px;
    margin-top: 15px;
}
.text {
    text-indent: 2em;
}
.image {
    width: 100%;
    display: flex;
    justify-content: center;
}
.item img {
    width: 50%;
}
.con-left {
    width: 80%;
}
.con-right {
    display: flex;
    flex-direction: column;
}
.application {
    display: flex;
    justify-content: center;
    font-size: 20px;
    height: 30px;
    width: 200px;
    background-color: #545c64;
    color: white;
    margin-bottom: 5px;
}
.con-right img {
    width: 198px;
    height: 150px;
    cursor: pointer;
    border: 1px solid;
}
.more {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    cursor: pointer;
    color: white;
    height: 30px;
    font-size: 20px;
    background-color: #545c64;
}
::v-deep .el-page-header__content {
    margin-top: 20px;
    margin-bottom: 20px;
}
</style>