<template>
    <navbar />
    <div class="main">
        <div class="title">
            关于滨水植物数据库
        </div>
        <div class="content">
            <p>滨水植物在城市规划和设计中扮演着重要的角色，它们不仅美化了城市景观，还具有生态保护和环境改善的功能。为了更好地指导工程设计师在施工时选择适合的滨水植物，我们开发了基于工程设计的滨水植物数据库项目。</p>
            <p>该数据库收集整理了各种类型的滨水植物信息，包括植物的名称、特征、生长习性、适宜生长环境等内容。通过该数据库，工程设计师可以根据具体的驳岸类型（比如混凝土护岸、湿地护岸等）查询到适合的滨水植物种类，并了解它们的生长特点和适用条件。
            </p>
            <p>同时，该数据库还提供了对应植物的图片和实际案例展示，帮助设计师更直观地了解不同植物的外观特征和实际应用效果，从而帮助设计师进行综合考量，选择最合适的滨水植物种类。</p>
            <p>通过这一项目，我们旨在为城市规划和工程设计提供更科学、可行的滨水植物选择方案，促进城市滨水区域的生态保护和景观提升。</p>
            <p>目前，该项目正在由福建省水利厅进行评审，作为福建省水利厅科技项目（MSK201917）的一部分，期待为相关领域提供更有价值的研究成果。</p>
        </div>
    </div>
    <Footer />
</template>

<script>
import Navbar from '@/components/Navbar.vue'
import Footer from '@/components/Footer.vue'
export default {
    components: {
        Navbar,
        Footer,
    },
}
</script>

<style scoped>
.main {
    margin: 5% 20%;
}

.title {
    font-size: 26px;
    font-weight: bold;
    text-align: center;
    margin-bottom: 50px;
}

.content {
    font-size: 18px;
    text-indent: 2em;
}
</style>