<template>
    <div>


        <div class="main">
            <div class="title">
                <div>基于工程设计的滨水植物数据库</div>
                <!-- <div style="text-align: left;">基于工程设计的</div> -->
                <!-- <div style="text-align: right;">滨水植物数据库</div> -->
            </div>
            <div class="login-page">
                <div class="login">登录</div>
                <el-form label-position="top" class="login-form" ref="loginForm">
                    <el-form-item>
                        <el-input class="input" v-model="form.username" clearable placeholder="请输入账号"></el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-input class="input" type="password" v-model="form.password" clearable
                            placeholder="请输入密码"></el-input>
                    </el-form-item>
                    <div style="display: flex;justify-content: space-between;align-items: center;">
                        <el-checkbox v-model="checked">记住密码</el-checkbox>
                        <a class="forget">忘记密码？</a>
                    </div>
                    <el-form-item>
                        <el-button class="tologin" type="primary" @click="login">登录</el-button>
                    </el-form-item>
                </el-form>
            </div>
        </div>
        <Footer></Footer>
    </div>
</template>

<script>
import api from '../router/axios'
import Footer from '@/components/Footer.vue'
export default {
    components: {
        Footer
    },
    data() {
        return {
            form: {
                username: '',
                password: ''
            },
            checked: false
        };
    },
    methods: {
        login() {
            // 发送登录请求
            api.post('/user/login?account=' + this.form.username + '&password=' + this.form.password,)
                .then((res) => {
                    if (res.data.status === 200) {
                        // 登录成功，跳转到首页
                        localStorage.setItem('token', res.data.data)
                        this.$router.push('/manage-index/plant-manage');
                        this.$message({
                            message: '登录成功',
                            type: 'success'
                        });
                    } else {
                        // 登录失败，提示错误信息
                        this.$message.error(res.data.msg);
                    }
                })
                .catch((error) => {
                    console.log(error)
                    this.$message.error(error);
                });
        },
    }
}
</script>

<style scoped>
.main {
    width: 100%;
    height: 100vh;
    background-image: url('../assets/image/carousel3.jpg');
    background-size: cover;
    display: flex;
}

.title {
    width: 45%;
    font-size: 65px;
    margin: auto;
    font-family: 'YouSheBiaoTiHei';
}

.login-page {
    width: 350px;
    height: 400px;
    background-color: white;
    margin: auto;
    margin-left: -100px;
}

.login {
    font-size: 25px;
    text-align: center;
    margin-top: 30px;
}

.login-form {
    width: 80%;
    margin: auto;
    margin-top: 35px;
}

.input {
    height: 50px;
    border-radius: 30px;
}

.tologin {
    background-color: #7070ff;
    width: 100%;
    height: 50px;
    font-size: 18px;
    margin-top: 30px;
}

::v-deep .el-input__inner {
    font-size: 15px;
}

::v-deep .el-form-item {
    margin-bottom: 20px;
}

.forget {
    font-size: 14px;
    cursor: pointer;
}
</style>