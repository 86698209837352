<template>
  <router-view/>

</template>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  margin: -8px -8px auto;
}

::-webkit-scrollbar {
  width: 8px; /* 设置滚动条宽度 */
}

::-webkit-scrollbar-track {
  background-color: #f1f1f1; /* 设置滚动条背景色 */
}

::-webkit-scrollbar-thumb {
  background-color: #c3c3c3; /* 设置滚动条滑块颜色 */
}

::-webkit-scrollbar-thumb:hover {
  background-color: #a9a9a9; /* 设置滚动条滑块悬停时的颜色 */
}

body {
  margin-bottom: 0 !important;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
</style>
