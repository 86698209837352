import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/Home.vue";
import Search from "../views/Search.vue";
import Picture from "../views/Picture.vue";
import PlantDetail from "../views/PlantDetail.vue";
import Application from "../views/Application.vue";
import AboutUs from "../views/AboutUs.vue";
import Login from "../views/Login.vue";
import AppDetail from "../views/AppDetail.vue";

const routes = [
    {
        path: "/",
        name: "home",
        component: Home,
    },
    {
        path: "/search",
        name: "search",
        component: Search,
    },
    {
        path: "/picture",
        name: "picture",
        component: Picture,
    },
    {
        path: "/plant-detail",
        name: "plantdetail",
        component: PlantDetail,
    },
    {
        path: "/application",
        name: "application",
        component: Application,
    },
    {
        path: "/aboutus",
        name: "aboutus",
        component: AboutUs,
    },
    {
        path: "/login",
        name: "login",
        component: Login,
    },
    {
        path: "/app-detail",
        name: "appdetail",
        component: AppDetail,
    },
    {
        path: "/manage-index",
        name: "ManageIndex",
        component: () => import(/* webpackChunkName: "ManageIndex" */ "@/views/ManageIndex.vue"),
        children: [
            {
                path: "plant-manage",
                name: "PlantManage",
                component: () =>
                    import(
                        /* webpackChunkName: "PlantManage" */ "@/views/ManageChild/PlantManage.vue"
                    ),
            },
            {
                path: "add-plant",
                name: "AddPlant",
                component: () =>
                    import(/* webpackChunkName: "AddPlant" */ "@/views/Detail/AddPlant.vue"),
            },
            {
                path: "view-detail",
                name: "ViewDetail",
                component: () =>
                    import(/* webpackChunkName: "ViewDetail" */ "@/views/Detail/ViewDetail.vue"),
            },
            {
                path: "revetment-manage",
                name: "RevetmentManage",
                component: () =>
                    import(
                        /* webpackChunkName: "RevetmentManage" */ "@/views/ManageChild/RevetmentManage.vue"
                    ),
            },
            {
                path: "add-revetment",
                name: "AddRevetment",
                component: () =>
                    import(
                        /* webpackChunkName: "AddRevetment" */ "@/views/Detail/AddRevetment.vue"
                    ),
            },
            {
                path: "revetment-detail",
                name: "RevetmentDetail",
                component: () =>
                    import(
                        /* webpackChunkName: "RevetmentDetail" */ "@/views/Detail/RevetmentDetail.vue"
                    ),
            },
            {
                path: "plant-family-manage",
                name: "PlantFamilyManage",
                component: () =>
                    import(
                        /* webpackChunkName: "PlantFamilyManage" */ "@/views/ManageChild/PlantFamilyManage.vue"
                    ),
            },
            {
                path: "add-plant-family",
                name: "AddPlantFamily",
                component: () =>
                    import(
                        /* webpackChunkName: "AddPlantFamily" */ "@/views/Detail/AddPlantFamily.vue"
                    ),
            },
            {
                path: "plant-family-detail",
                name: "PlantFamilyDetail",
                component: () =>
                    import(
                        /* webpackChunkName: "PlantFamilyDetail" */ "@/views/Detail/PlantFamilyDetail.vue"
                    ),
            },
            {
                path: "revetment-type-manage",
                name: "RevetmentTypeManage",
                component: () =>
                    import(
                        /* webpackChunkName: "RevetmentTypeManage" */ "@/views/ManageChild/RevetmentTypeManage.vue"
                    ),
            },
            {
                path: "add-revetment-type",
                name: "AddRevetmentType",
                component: () =>
                    import(
                        /* webpackChunkName: "AddRevetmentType" */ "@/views/Detail/AddRevetmentType.vue"
                    ),
            },
            {
                path: "revetment-type-detail",
                name: "RevetmentTypeDetail",
                component: () =>
                    import(
                        /* webpackChunkName: "RevetmentTypeDetail" */ "@/views/Detail/RevetmentTypeDetail.vue"
                    ),
            },
            {
                path: "user-manage",
                name: "UserManage",
                component: () =>
                    import(
                        /* webpackChunkName: "UserManage" */ "@/views/ManageChild/UserManage.vue"
                    ),
            },
        ],
    },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

export default router;
