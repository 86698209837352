<template>
    <navbar/>
    <div class="main">
        <div class="left">
            <!-- <ul class="list-style">
                <li @click="changeColor($event); changeType(1)" class="selected">全部</li>
                <li @click="changeColor($event); changeType(2)">垂直驳岸</li>
                <li @click="changeColor($event); changeType(3)">砌石驳岸</li>
                <li @click="changeColor($event); changeType(4)">阶梯入水驳岸</li>
                <li @click="changeColor($event); changeType(5)">自然驳岸</li>
            </ul> -->
            <ul class="list-style" >
                <li @click="changeColor($event); changeType(0)" class="selected">全部</li>
                <li v-for="item in RevetmentType" :key="item" @click="changeColor($event); changeType(item.name)">
                    {{item.name}}
                </li>
            </ul>
        </div>
        <div class="right">
            <div class="content">
                <div class="item" v-for="item in itemlist" :key="item" @click="toAppDetail(item)">
                    <el-card :body-style="{ padding: '0px', height: '400px' }">
                        <img :src="item.images[0].imageUrl"/>
                        <div style="padding: 14px;display: flex;flex-direction: column;text-align: center;color: white;background-color: #42666e;">
                            <span>{{ item.name }}</span>
                            <div style="display: flex;justify-content: space-evenly;">
                                <span v-for="item1 in item.plants" :key="item1">{{ item1.name }}</span>
                            </div>
                        </div>
                    </el-card>
                </div>
            </div>
            <div class="pagination">
                <el-pagination
                    background
                    layout="prev, pager, next"
                    :current-page="pageNum"
                    :page-size="pageSize"
                    :total="total"
                    @current-change="handlePageChange"
                    />
            </div>
        </div>
    </div>
    <Footer/>
</template>

<script>
import api from '@/router/axios.js'
import Navbar from '@/components/Navbar.vue'
import Footer from '@/components/Footer.vue'
export default {
    components: {
        Navbar,
        Footer,
    },
    data() {
        return {
            pageNum: 1,
            pageSize: 6,
            total: 0,
            itemlist: [],
            RevetmentType: [],
            type: '',
        }
    },
    methods: {
        changeColor(event) {
            const previousSelected = document.querySelector('.list-style li.selected');
            if (previousSelected) {
                previousSelected.classList.remove('selected');
            }
            const currentSelected = event.target;
            currentSelected.classList.add('selected');
        },
        changeType(type) {
            if(type === 0) {
                this.type = ''
                this.getList()
            } else {
                this.type = type
                this.getList()
            }
        },
        toAppDetail(data) {
            this.$router.push({ path: '/app-detail', query: { data: JSON.stringify(data)}});
        },
        handlePageChange(currentPage) {
            this.pageNum = currentPage
            this.getList()
        },
        getList() {
            api.post('/revetment/getRevetmentByName?name=' + this.type + '&page=' + this.pageNum + '&pageSize=' + this.pageSize)
                .then((res)=> {
                    this.total = res.data.data.numbers
                    this.itemlist = res.data.data.data
                    this.itemlist.forEach((item) => {
                        item.images.forEach((item) => {
                            item.imageUrl = 'https://plant-database.oss-cn-beijing.aliyuncs.com/' + item.imageUrl
                        })
                    })
                })
                .catch((error)=> {
                    console.log(error)
                })
        },
        getRevetmentType() {
            api.post('/revetment/getRevetmentType')
                .then((res)=> {
                    this.RevetmentType = []
                    res.data.data.forEach((item) => {
                        this.RevetmentType.push({ name: item.name, id: item.id })
                    })
                })
                .catch((error)=> {
                    console.log(error)
                })
        }, 
    },
    created() {
        this.getRevetmentType();
        this.getList();
    },
}
</script>

<style scoped>
.main {
    width: 82%;
    margin: 5% 9%;
    display: flex;
}
.left {
    width: 20%;
    height: 300px;
    display: flex;
    justify-content: center;
    border: 1px solid #e1e4e8;
}
.list-style {
    width: 100%;
    list-style: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0;
    font-size: 22px;
    text-align: center;
}
.list-style li {
    height: 50px;
    line-height: 50px;
    cursor: pointer;
}
.list-style li:hover {
    background-color: #04c5a0;
}
.list-style li.selected {
    background-color: #04c5a0;
}
.right {
    width: 87%;
    margin-left: 5%;
    border-top: 1px solid #e1e4e8;
}
.content {
    display: flex;
    flex-wrap: wrap;
}
.item {
    width: 48%;
    display: flex;
    flex-direction: column;
    margin: 1%;
}
.item img {
    width: 100%;
    height: 325px;
}
.pagination {
    display: flex;
    justify-content: center;
    margin-top: 50px;
}
.nodata {
    text-align: center;
    font-size: 30px;
    margin: 125px 0;
}
</style>